html,
body {
  height: 100%;
}

iframe {
  display: none;
}

/* body,
p,
a,
h1,
h2,
h3,
h4,
h5,
a,
i,
* {
  font-family: "Poppins";
} */

p,
li {
  font-size: 16px;
}

@media(max-width: 480px) {
  .bg-cover {
    background-size: cover !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ccc;
}

.bg-gray {
  background-color: #eee;
}

.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  gap: 4px;
  background: #cae3f9;
  border-radius: 30px;
  width: 112px;
  height: 23px;
  color: black !important;
}


/* copy */



.scrolled-down {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.scrolled-up {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .4s .2s ease-in-out;
  transition: all .4s .2s ease-in-out;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
}

.dark-mode .page-loading {
  background-color: #0b0f19;
}

.page-loading.active {
  opacity: 1;
  visibility: visible;
}

.page-loading-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
  opacity: 0;
}

.page-loading.active>.page-loading-inner {
  opacity: 1;
}

.page-loading-inner>span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  color: #9397ad;
}

.dark-mode .page-loading-inner>span {
  color: #fff;
  opacity: .6;
}

.page-spinner {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: .75rem;
  vertical-align: text-bottom;
  border: .15em solid #b4b7c9;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner .75s linear infinite;
  animation: spinner .75s linear infinite;
}

.dark-mode .page-spinner {
  border-color: rgba(255, 255, 255, .4);
  border-right-color: transparent;
}

@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-scroll-top-alt {
  --si-btn-scroll-top-size: 2.5rem;
  --si-btn-scroll-top-font-size: 1.15rem;
  --si-btn-scroll-top-color: var(--si-gray-800);
  --si-btn-scroll-top-bg: var(--si-body-bg);
  --si-btn-scroll-top-border-radius: 50%;
  --si-btn-scroll-top-box-shadow: 0 0.275rem 1.125rem rgba(18, 21, 25, 0.1);
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 1rem;
  bottom: 1rem;
  width: var(--si-btn-scroll-top-size);
  height: var(--si-btn-scroll-top-size);
  -webkit-transition: opacity 0.25s, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s, -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s;
  transition: transform 0.25s ease-in-out, opacity 0.25s, -webkit-transform 0.25s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
  border-radius: 50%;
  color: var(--si-btn-scroll-top-color) !important;
  font-size: var(--si-btn-scroll-top-font-size);
  -webkit-box-shadow: var(--si-btn-scroll-top-box-shadow);
  box-shadow: var(--si-btn-scroll-top-box-shadow);
  text-decoration: none;
  opacity: 0;
  z-index: 1030;
}

.btn-scroll-top-alt::before {
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  width: calc(var(--si-btn-scroll-top-size) - .125rem);
  height: calc(var(--si-btn-scroll-top-size) - .125rem);
  background-color: var(--si-btn-scroll-top-bg);
  border-radius: 50%;
  z-index: -1;
  content: "";
}

.btn-scroll-top-alt>svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.btn-scroll-top-alt.show {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}


.cs-frame-phone {
  position: relative;
  width: 320px;
  margin: 0 15px 20px 15px;
  border: 6px solid #f7f7f7;
  border-radius: 36px;
}

.cs-frame-phone-light {
  position: relative;
  width: 320px;
  margin: 0 15px 20px 15px;
  border: 6px solid #fff;
  border-radius: 36px;
}

.cs-frame-phone::after,
.cs-frame-phone::before {
  display: block;
  position: absolute;
  left: -35px;
  bottom: -10px;
  width: 380px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
  filter: blur(13px);
  opacity: 0.55;
  z-index: -1;
  content: "";
}

.cs-frame-phone::before {
  left: 5px;
  width: 300px;
  filter: blur(10px);
  opacity: 0.18;
}

.cs-frame-phone-body {
  position: relative;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.cs-frame-phone-body::before,
.cs-frame-phone-body::after {
  position: absolute;
  background-color: #37384e;
  content: "";
  z-index: 5;
}

.cs-frame-phone-body img {
  display: block;
  width: 100%;
  height: 100%;
}

.cs-frame-browser {
  position: relative;
  width: 520px;
  padding: .5rem .5rem .5rem .5rem;
  border: 2px solid #37384e;
  border-radius: 1.125rem;
}

.cs-frame-browser-light {
  position: relative;
  width: 520px;
  padding: .5rem .5rem .5rem .5rem;
  border: 2px solid #e3e3ee;
  border-radius: 1.125rem;
}

.cs-frame-browser.border-light {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.cs-frame-browser-body {
  position: relative;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.06), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.cs-frame-browser-body img {
  display: block;
  width: 100%;
}

.cs-frame-browser-button {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 0.625rem;
  border-radius: 50%;
}


@media (min-width: 768px) {
  .cs-frame-browser {
    padding: 4rem 2.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
  }

  .cs-frame-browser-toolbar {
    top: 1.25rem;
    left: 1.625rem;
  }

  .cs-frame-browser-body {
    border-radius: 1rem;
  }
}





.cmViewInArContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  /*background-color: #ff4f00;*/
  /*border: 1px solid rgba(217,217,217,.5);*/
  /*filter: drop-shadow(2px 2px 4px rgba(0,0,0,.096));*/
  -webkit-transition: background-color .6s cubic-bezier(.19, 1, .22, 1);
  transition: background-color .6s cubic-bezier(.19, 1, .22, 1);
  /*border-radius: 5px*/
}

.cmViewInArContainer.cmViewInAr_isQrOpen {
  pointer-events: none
}

.cmViewInArContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: #ff4f00;*/
  -webkit-transition: opacity .6s cubic-bezier(.19, 1, .22, 1);
  transition: opacity .6s cubic-bezier(.19, 1, .22, 1);
  /*border-radius: 5px;*/
  opacity: 0;
  z-index: -1
}

.cmViewInArContainer:not(.cmViewInAr_isQrOpen):after {
  opacity: 1
}

.cmViewInArContainer.cmViewInAr_isQrOpen .cmViewInArButtonText {
  opacity: 0;
  visibility: hidden
}

.cmViewInArContainer:not(.cmViewInAr_isQrOpen) .cmViewInArButtonText {
  opacity: 1;
  visibility: visible
}

.cmViewInArContainer.cmViewInAr_isQrOpen .cmViewInArQrCode {
  opacity: 1;
  visibility: visible;
}

.cmViewInArContainer:not(.cmViewInAr_isQrOpen) .cmViewInArQrCode {
  opacity: 0;
  visibility: hidden;
}

.cmViewInArButtonText {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
}

.cmViewInArQrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -240px;
  left: 0;
  z-index: -1;
  min-width: 220px;
  min-height: 265px;
  width: 100%;
  padding-bottom: 0;
  background-color: #ff4f00;
  -webkit-transition: background-color .6s cubic-bezier(.19, 1, .22, 1);
  transition: background-color .6s cubic-bezier(.19, 1, .22, 1), opacity 1s;
}


.text-left {
  text-align: left !important;
}

.img-box-shadow {
  border-radius: 10px;
  box-shadow: 0 0 3px #eee;
}